import React, { useContext, useRef, useEffect, useState, Suspense, lazy } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Field, Formik, FieldArray } from 'formik';
import { getHeaders } from '../../request';
import axios from 'axios';
import HttpStatus from 'http-status-codes';
import LaudoTextualLesao from './abaTextualLesao';
import ModalConfirmacao from './modalConfirmacao';
import SttCid10 from '@stt-componentes/cid10';
import SttDecs from '@stt-componentes/decs';
import { useSignals } from '@preact/signals-react/runtime';
import { useSignal } from '@preact/signals-react';
import alerta from '../../signals/alerta';
import { laudar } from '../../signals/laudo';
import { listar } from '../../signals/exame';

import {
	SttHeading,
	SttFormControl,
	SttFormGroup,
	SttFormControlLabel,
	SttCheckbox,
	SttExpansionPanel,
	SttGrid,
	SttTabs,
	SttButton,
	SttLoading,
	SttModal,
	SttCircularProgress,
	SttTranslateHook
} from '@stt-componentes/core';
import values from './initialValues';
import schema from './validationSchema';
import {
	LESOES,
	LESAO_FUNDAMENTAL,
	COR,
	SUPERFICIE,
	COMPLEMENTO_LESAO_FUNDAMENTAL,
	OUTRA_COR,
	ESTRUTURA_AFETADA,
	LADO_AFETADO,
	REGIAO_AFETADA,
	ARCADA_AFETADA,
	OCORRENCIA_LESAO
} from '../solicitacao/lesoes/fieldNames';
import { CLASSIFICACAO_RISCO, LAUDO_IMPORTANTE } from './fieldNames';
import { VERSAO } from '../../common/Constants';
const Boca = lazy(() => import('../boca/index'));

const useStyles = makeStyles((theme) => ({
	conteinerDecs: {
		paddingTop: theme.spacing(2)
	},
	tabs: {
		padding: '0'
	}
}));

const FormLaudo = (props) => {
	const {
		callbackProximoExame,
		dados: { exame, lesoes, idLaudoEmissao }
	} = props;
	const { strings } = useContext(SttTranslateHook.I18nContext);
	const ESTOMATO_API_BASE_URL = global.gConfig.url_base_estomato;

	const location = useLocation();
	const classes = useStyles();

	const [listaLesoes, setListaLesoes] = useState(lesoes);
	const [versaoExame, setVersaoExame] = useState(exame.versao);
	const [indiceLesao, setIndiceLesao] = useState(null);
	const [novaEstruturaAfetada, setNovaEstruturaAfetada] = useState(null);

	// Importância do laudo
	const laudoImportante = useSignal(false);

	// Abas para cada lesão
	const abasLaudoTextual = useSignal([]);
	const abaTextualAtiva = useSignal('0');
	const abasComErro = useSignal({});

	// Controles do modal de confirmação
	const modalAberto = useSignal(false);
	const laudo = useSignal(null);

	// Controles do formulário
	const validationSchema = useSignal({});
	const initialValues = useSignal(null);
	const contextoFabricalaudo = useSignal(location.pathname === '/laudo');

	const lesaoFundamentalRef = useRef(null);
	const tamanhoRef = useRef(null);
	const corRef = useRef(null);
	const superficieRef = useRef(null);
	const classificacaoRiscoRef = useRef(null);
	const estruturaRef = useRef(null);
	const regiaoRef = useRef(null);
	const ladoAfetadoRef = useRef(null);
	const arcadaAfetadaRef = useRef(null);
	const ocorrenciaLesaoRef = useRef(null);

	useSignals();

	useEffect(() => {
		initialValues.value = values({ exame, lesoes: listaLesoes, idLaudoEmissao });
		validationSchema.value = schema(strings, versaoExame);

		if (listaLesoes.length === 0) {
			setListaLesoes(initialValues.value.lesoes);
			setVersaoExame(VERSAO.ANTIGA);
		}

		let abasTextual = [];
		listaLesoes.forEach((lesao, idx) => {
			const numeroLesao = lesao.numero;
			let titulo = `${strings.lesao} ${numeroLesao}`;
			if (lesao.descricao_lesao) {
				titulo = titulo.concat(` - ${lesao.descricao_lesao}`);
			}

			// Aba do laudo textual
			abasTextual.push({
				refs: {
					lesaoFundamentalRef,
					tamanhoRef,
					estruturaRef,
					regiaoRef,
					ladoAfetadoRef,
					arcadaAfetadaRef,
					corRef,
					superficieRef,
					ocorrenciaLesaoRef,
					classificacaoRiscoRef
				},
				lesoes: listaLesoes,
				titulo,
				versao: versaoExame,
				numeroLesao,
				indice: idx,
				conteudo: LaudoTextualLesao
			});
		});

		abasLaudoTextual.value = abasTextual;
	}, [listaLesoes]);

	const callbackCancelar = () => {
		modalAberto.value = false;
	};

	const callbackConfirmar = () => {
		modalAberto.value = false;
		if (contextoFabricalaudo.value) {
			callbackProximoExame();
		} else {
			laudar.value = false;
			listar.value = true;
		}
	};

	const fecharAlteracaoEstruturaAfetada = () => {
		setNovaEstruturaAfetada(null);
		setIndiceLesao(null);
	};

	return (
		initialValues.value && (
			<Formik
				enableReinitialize
				initialValues={initialValues.value}
				validationSchema={validationSchema.value}
				onSubmit={(data, { setSubmitting }) => {
					axios
						.post(`${ESTOMATO_API_BASE_URL}/laudo/resumo`, data, { headers: getHeaders() })
						.then((response) => {
							laudo.value = response.data.data;
							modalAberto.value = true;
						})
						.catch((err) => {
							console.log(err);
							const { response } = err;
							let msg = strings.erroDesconhecido;
							if (response?.status === HttpStatus.BAD_REQUEST) {
								let arrMensagem = [];
								response.data.errors.forEach((error) => {
									arrMensagem.push(`- ${error.message}`);
								});
								msg = arrMensagem.join('\n');
							}

							alerta.value = {
								title: strings.erro,
								message: msg,
								type: 'error',
								open: true,
								options: [
									{
										title: strings.ok,
										onClick: () => {
											alerta.value = { ...alerta.value, open: false };
										}
									}
								],
								onClose: () => {
									alerta.value = { ...alerta.value, open: false };
								}
							};
						})
						.finally(() => {
							setSubmitting(false);
						});
				}}
			>
				{({ values: val, errors, isSubmitting, submitCount, setFieldValue, handleSubmit }) => {
					return (
						<div>
							<form
								onSubmit={handleSubmit}
								noValidate
							>
								<SttExpansionPanel
									title={strings.laudoTextual}
									compact
									children={
										<FieldArray
											name={LESOES}
											render={({ remove }) => (
												<SttGrid
													container
													spacing={3}
												>
													<SttGrid
														item
														xs={12}
														sm={12}
													>
														<SttTabs
															abas={abasLaudoTextual.value}
															abaAtiva={abaTextualAtiva.value}
															canClose={abasLaudoTextual.value.length > 1}
															handleChangeAbaAtiva={(aba) => (abaTextualAtiva.value = aba)}
															handleCloseTab={(indice) => {
																remove(indice[0]);
																let lesoes = [...listaLesoes];
																lesoes.splice(indice[0], 1);
																setListaLesoes(lesoes);
															}}
															className={classes.tabs}
															errors={abasComErro.value}
															canEdit={versaoExame === VERSAO.NOVA}
															handleEditTab={(tituloAba, indice) => setIndiceLesao(indice)}
														/>
													</SttGrid>
												</SttGrid>
											)}
										/>
									}
								/>

								<SttModal
									title={strings.alterarEstruturaAfetada}
									open={indiceLesao !== null}
									iconClose={fecharAlteracaoEstruturaAfetada}
									maxWidth="md"
									fullWidth={true}
									children={
										<Suspense fallback={<SttCircularProgress color="primary" />}>
											<Boca
												points={novaEstruturaAfetada ? [novaEstruturaAfetada] : []}
												drawPointListener={(e) => setNovaEstruturaAfetada(e)}
											/>
										</Suspense>
									}
									footer={
										<div>
											<SttButton
												variant="contained"
												color="primary"
												onClick={() => {
													const lesao = {
														...listaLesoes[indiceLesao],
														estrutura_afetada: novaEstruturaAfetada.idCorpo,
														descricao_lesao: novaEstruturaAfetada.featureName,
														coordenada_x: novaEstruturaAfetada.x,
														coordenada_y: novaEstruturaAfetada.y
													};
													setListaLesoes(listaLesoes.map((l) => (l.numero !== lesao.numero ? l : lesao)));
													fecharAlteracaoEstruturaAfetada();
												}}
											>
												{strings.alterar}
											</SttButton>
											<SttButton
												variant="outlined"
												color="primary"
												onClick={fecharAlteracaoEstruturaAfetada}
											>
												{strings.cancelar}
											</SttButton>
										</div>
									}
								/>

								<SttGrid
									item
									xs={12}
									className={classes.checkboxCenter}
								>
									<Field name={LAUDO_IMPORTANTE}>
										{({ field: { onChange, checked, ...other } }) => (
											<SttFormControl variant="outlined">
												<SttHeading
													variant="h4"
													color="primary"
												>
													{strings.importancia}
												</SttHeading>
												<SttFormGroup row>
													<SttFormControlLabel
														control={
															<SttCheckbox
																{...other}
																color="primary"
																checked={checked || laudoImportante.value}
																onChange={(e) => {
																	const isChecked = e.target.checked;
																	laudoImportante.value = isChecked;
																	setFieldValue(LAUDO_IMPORTANTE, isChecked);
																}}
															/>
														}
														label={strings.laudoImportante}
													/>
												</SttFormGroup>
											</SttFormControl>
										)}
									</Field>
								</SttGrid>

								<SttExpansionPanel
									title={strings.descritores}
									compact
									opened={false}
									children={
										<>
											<SttGrid
												container
												spacing={3}
											>
												<SttGrid
													item
													xs={12}
												>
													<div className={classes.conteinerDecs}>
														<SttCid10
															strings={strings}
															headers={getHeaders()}
															multiplos
															formExterno={{
																cid10: val.cid10,
																isSubmitting,
																errors,
																submitCount,
																setFieldValue
															}}
														/>
													</div>
												</SttGrid>
											</SttGrid>
											<SttGrid
												container
												spacing={3}
											>
												<SttGrid
													item
													xs={12}
												>
													<div className={classes.conteinerDecs}>
														<SttDecs
															strings={strings}
															multiplos
															formExterno={{
																decs: val.decs,
																isSubmitting,
																errors,
																submitCount,
																setFieldValue
															}}
														/>
													</div>
												</SttGrid>
											</SttGrid>
										</>
									}
								/>
								<SttGrid
									container
									spacing={3}
								>
									<SttGrid
										item
										xs={12}
									>
										<SttButton
											type="submit"
											variant="contained"
											color="primary"
											disabled={isSubmitting}
											nomarginleft="true"
											onClick={() => {
												if (errors[LESOES]) {
													let node;
													for (const [indice, erro] of errors[LESOES].entries()) {
														if (erro) {
															abasComErro.value = { [indice.toString()]: true };
															abaTextualAtiva.value = indice.toString();
															if (erro[LESAO_FUNDAMENTAL] || erro[COMPLEMENTO_LESAO_FUNDAMENTAL]) {
																node = lesaoFundamentalRef.current;
															} else if (erro[ESTRUTURA_AFETADA]) {
																node = estruturaRef.current;
															} else if (erro[LADO_AFETADO]) {
																node = ladoAfetadoRef.current;
															} else if (erro[REGIAO_AFETADA]) {
																node = regiaoRef.current;
															} else if (erro[ARCADA_AFETADA]) {
																node = arcadaAfetadaRef.current;
															} else if (erro[COR] || erro[OUTRA_COR]) {
																node = corRef.current;
															} else if (erro[SUPERFICIE]) {
																node = superficieRef.current;
															} else if (erro[OCORRENCIA_LESAO]) {
																node = ocorrenciaLesaoRef.current;
															} else if (erro[CLASSIFICACAO_RISCO]) {
																node = classificacaoRiscoRef.current;
															}
															if (node) {
																setTimeout(() => {
																	node.scrollIntoView({
																		behavior: 'smooth',
																		block: 'center',
																		inline: 'start'
																	});
																}, 500);
															}
															break;
														}
													}
												}
											}}
										>
											{strings.publicar}
										</SttButton>
									</SttGrid>
								</SttGrid>
							</form>
							<SttLoading
								open={isSubmitting}
								text={strings.gerandoResumo}
							/>
							<ModalConfirmacao
								html={laudo}
								open={modalAberto.value}
								idExame={exame.id_exame}
								callbackConfirmar={callbackConfirmar}
								callbackCancelar={callbackCancelar}
							/>
						</div>
					);
				}}
			</Formik>
		)
	);
};

FormLaudo.propTypes = {
	dados: PropTypes.func.isRequired,
	callbackProximoExame: PropTypes.func
};

export default FormLaudo;
